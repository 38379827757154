import React from 'react';

import 'react-toastify/dist/ReactToastify.css';

// Remix Icon
import 'remixicon/fonts/remixicon.css';

// App Styles
import './styles/style.scss';

// App Layout & Component
import AppHeader from './layouts/AppHeader';
import AppMain from './layouts/AppMain';
import AppFooter from './layouts/AppFooter';
import AppToggleModalBtns from './layouts/AppToggleModalBtns';
import UserService from './services/user.service';

export default function App() {
  return (
    <div
      className={
        'EAU_ET_NATURE' === UserService.applicationContext()
          ? 'fr-app fr-app-eaunature'
          : 'fr-app fr-app-icpe'
      }
    >
      <AppHeader />
      <AppMain />
      <AppToggleModalBtns />
      <AppFooter />
    </div>
  );
}

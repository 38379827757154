import axios from 'axios';
import React, { useState, useEffect } from 'react';
import LoaderCommissionnement from '../../components/shared/loaders/LoaderCommissionnement';
import TechnicalError from '../../components/shared/technicalError/TechnicalError';
import { stats } from '../../configs/routesAPI';
import { saveAs } from 'file-saver';
import TableWithLoader from '../../components/shared/tables/TableWithLoader';
import { ButtonGroup } from '@dataesr/react-dsfr';
import { Link } from 'react-router-dom';

export default function PagesStatsAgentsCommissionnesStatutListe() {
  const [agentsCommissionnesStatut, setAgentsCommissionnesStatut] = useState(
    []
  );
  const columns = [
    {
      name: 'label',
      label: 'Statut',
      cellClassRender: ({ label }) => {
        return label === 'Total' ? 'fr-app-green-bg' : '';
      },
    },
    {
      name: 'nb_agents',
      label: 'Total',
      cellClassRender: ({ label }) => {
        return label === 'Total' ? 'fr-app-green-bg' : '';
      },
    },
    {
      name: 'pourcent',
      label: '%',
      cellClassRender: ({ label }) => {
        return label === 'Total' ? 'fr-app-green-bg' : '';
      },
    },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    axios
      .get(stats.getAgentsCommissionnesStatut)
      .then((response) => {
        setAgentsCommissionnesStatut(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setApiError(error);
        setIsLoading(false);
      });
  }, []);

  const downloadCsv = (event) => {
    event.preventDefault();

    axios({
      url: stats.exportAgentsCommissionnesStatut,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'text/csv;',
      });
      saveAs(blob, 'Synthèse_agents_commissionnés_statut.csv');
    });
  };

  return isLoading ? (
    <LoaderCommissionnement />
  ) : (
    <>
      <div>
        {!isLoading ? <TechnicalError error={apiError} /> : <></>}

        <section className="fr-app-section">
          <div className="fr-table fr-app-table no-padding-top">
            <div className="fr-app-table">
              <caption style={{ display: 'block' }}>
                Synthèse des agents commissionnés par statut
              </caption>
            </div>
            <TableWithLoader
              data={agentsCommissionnesStatut}
              columns={columns}
              isLoading={isLoading}
              rowKey="agent_id"
              customClassName="stats-agents-commissionnes-statut no-caption"
            ></TableWithLoader>
          </div>

          <ButtonGroup isEquisized align="center" isInlineFrom="md">
            <Link
              type="button"
              className="fr-btn--md fr-btn btn-action-trigger"
              title="Export CSV"
              onClick={downloadCsv}
              to={''}
              disabled={isLoading}
              download
            >
              Export CSV
            </Link>
          </ButtonGroup>
        </section>
      </div>
    </>
  );
}

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import LoaderCommissionnement from '../../components/shared/loaders/LoaderCommissionnement';
import TechnicalError from '../../components/shared/technicalError/TechnicalError';
import { stats } from '../../configs/routesAPI';
import { saveAs } from 'file-saver';
import TableWithLoader from '../../components/shared/tables/TableWithLoader';
import { ButtonGroup, Col, Container, Row } from '@dataesr/react-dsfr';
import { Link } from 'react-router-dom';
import SelectAnnee from '../../components/shared/selects/SelectAnnee';
import { useForm } from 'react-hook-form';
import { localStorageSearchKey } from '../../configs/localStorageSearchKey';

export default function PagesStatsAgentsZoneChangeeAnneeListe() {
  const [agentsZoneChangeeAnnee, setAgentsZoneChangeeAnnee] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const currentYear = new Date().getFullYear();

  // Génération de la liste déroulantes des année pour le filtre en entrée
  const { register, getValues, setValue } = useForm({
    defaultValues: {
      annee: currentYear - 1,
    },
  });

  const params = {
    annee: null,
  };

  useEffect(() => {
    // Initialisation des valeurs du formulaires
    let inputParam = localStorage.getItem(
      localStorageSearchKey.searchStatistiquesListe
    );
    if (inputParam !== null) {
      inputParam = JSON.parse(inputParam);
      setValue('annee', inputParam.annee);
    }
    getData();
  }, []);

  const getData = () => {
    params.annee = getValues('annee');
    localStorage.setItem(
      localStorageSearchKey.searchStatistiquesListe,
      JSON.stringify(params)
    );
    axios
      .get(stats.getAgentsZoneChangeeAnnee, { params })
      .then((response) => {
        const data = response.data.data;
        setAgentsZoneChangeeAnnee(data.lignes);
        setColumns(applyStyling(data.colonnes));
        setIsLoading(false);
      })
      .catch((error) => {
        setApiError(error);
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    setValue(e.target.name, e.target.value);
    getData();
  };

  const applyStyling = (columns) => {
    return columns.map((col) => {
      return {
        name: col.name,
        label: col.label,
        cellClassRender: ({ service_accueil, region }) => {
          return (service_accueil && service_accueil === 'Total') ||
            (region && region === 'Total')
            ? 'fr-app-green-bg'
            : '';
        },
      };
    });
  };

  const downloadCsv = (event) => {
    event.preventDefault();

    axios({
      url: stats.exportAgentsZoneChangeeAnnee,
      params: { annee: getValues('annee') },
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'text/csv;',
      });
      saveAs(
        blob,
        'Synthèse_agents_zone_changée_' + getValues('annee') + '.csv'
      );
    });
  };

  return isLoading ? (
    <LoaderCommissionnement />
  ) : (
    <>
      <div>
        {!isLoading ? <TechnicalError error={apiError} /> : <></>}

        <section className="fr-app-section">
          <form className="fr-app-form">
            <Container fluid>
              <Row className={'align-center'}>
                <Col n="3">
                  <SelectAnnee
                    register={register}
                    handleChange={handleChange}
                    anneeMin={2010}
                    anneeMax={currentYear}
                  ></SelectAnnee>
                </Col>
              </Row>
            </Container>
          </form>
          <div className="fr-table fr-app-table no-padding-top">
            <div className="fr-app-table">
              <caption style={{ display: 'block' }}>
                Synthèse des agents ayant changé de zone en {getValues('annee')}
              </caption>
            </div>
            <TableWithLoader
              data={agentsZoneChangeeAnnee}
              columns={columns}
              isLoading={isLoading}
              rowKey="agent_id"
              customClassName="stats-agents-zone-changee-annee no-caption"
            ></TableWithLoader>
          </div>

          <ButtonGroup isEquisized align="center" isInlineFrom="md">
            <Link
              type="button"
              className="fr-btn--md fr-btn btn-action-trigger"
              title="Export CSV"
              onClick={downloadCsv}
              to={''}
              disabled={isLoading}
              download
            >
              Export CSV
            </Link>
          </ButtonGroup>
        </section>
      </div>
    </>
  );
}

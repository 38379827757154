import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonGroup, Button } from '@dataesr/react-dsfr';

import ConsulterPrestationsDeSermentEnAttenteTable from './ConsulterPrestationsDeSermentEnAttenteTable';
import { enCoursDeTraitement } from '../../../configs/routes';
import ApiService from '../../../services/api.service';
import { formationsRoutesAPI } from '../../../configs/routesAPI';

export default function PagesConsulterPrestationsDeSermentEnAttente() {
  let navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ApiService.fetchData(
      formationsRoutesAPI.aTraiter.prestationsDeSermentAValider,
      setData
    );
  }, []);

  const setData = (agents) => {
    setAgents(agents);
    setIsLoading(false);
  };

  return (
    <section className="fr-app-section">
      <ConsulterPrestationsDeSermentEnAttenteTable
        agents={agents}
        isLoading={isLoading}
      ></ConsulterPrestationsDeSermentEnAttenteTable>

      <div style={{ marginTop: '1.25rem' }}>
        <ButtonGroup isEquisized align="center" isInlineFrom="md">
          <Button onClick={() => navigate(enCoursDeTraitement.base)} secondary>
            Retour à la liste
          </Button>
        </ButtonGroup>
      </div>
    </section>
  );
}

export const gestionProfilsBaseName = '/gestiondesprofils';

export const lookupTablesRoutesBaseName = '/parametrage';

export const lookupTablesRoutesRelative = {
  tablesReference: '/tablesreference',
  tablesReferenceOnglet: '/tablesreference/:onglet',
  tablesReferenceOngletSelect: '/tablesreference/:onglet/:select',

  tablesReferenceOngletSelectAction: '/tablesreference/:onglet/:select/:action',
  tablesReferenceOngletSelectActionId:
    '/tablesreference/:onglet/:select/:action/:id',

  agents: '/tablesreference/agents',

  ajouterAgentStatus: '/tablesreference/agents/statuts/ajouter',
  modifierAgentStatus: '/tablesreference/agents/statuts/modifier/:id',

  ajouterAgentStatusGroup: '/tablesreference/agents/groupesstatut/ajouter',
  modifierAgentStatusGroup:
    '/tablesreference/agents/groupesstatut/modifier/:id',

  ajouterTypeAdministration: '/tablesreference/agents/typeadmin/ajouter',
  modifierTypeAdministration: '/tablesreference/agents/typeadmin/modifier/:id',

  ajouterService: '/tablesreference/agents/services/ajouter',
  modifierService: '/tablesreference/agents/services/modifier/:id',

  ajouterDominanteMetier: '/tablesreference/agents/dominantemetier/ajouter',
  modifierDominanteMetier:
    '/tablesreference/agents/dominantemetier/modifier/:id',

  ajouterCursusAllege: '/tablesreference/agents/cursusalleges/ajouter',
  modifierCursusAllege: '/tablesreference/agents/cursusalleges/modifier/:id',

  formations: '/tablesreference/formations',

  ajouterCursus: '/tablesreference/formations/cursus/ajouter',
  modifierCursus: '/tablesreference/formations/cursus/modifier/:id',

  ajouterModule: '/tablesreference/formations/modules/ajouter',
  modifierModule: '/tablesreference/formations/modules/modifier/:id',

  ajouterOrganisme: '/tablesreference/formations/organismes/ajouter',
  modifierOrganisme: '/tablesreference/formations/organismes/modifier/:id',

  ajouterLieu: '/tablesreference/formations/lieux/ajouter',
  modifierLieu: '/tablesreference/formations/lieux/modifier/:id',

  commissionnement: '/tablesreference/commissionnements',

  createHabilitation:
    '/tablesreference/commissionnements/habilitations/ajouter',
  updateHabilitation:
    '/tablesreference/commissionnements/habilitations/modifier/:id',

  createZoneCommissionnement:
    '/tablesreference/commissionnements/zonescommissionnements/ajouter',
  updateZoneCommissionnement:
    '/tablesreference/commissionnements/zonescommissionnements/modifier/:id',
  createExpediteurBordereau:
    '/tablesreference/commissionnements/expediteursbordereau/ajouter',
  updateExpediteurBordereau:
    '/tablesreference/commissionnements/expediteursbordereau/modifier/:id',
  createDestinataireBordereau:
    '/tablesreference/commissionnements/destinatairesbordereau/ajouter',
  updateDestinataireBordereau:
    '/tablesreference/commissionnements/destinatairesbordereau/modifier/:id',

  consulterMailCc: '/mailscc',
  ajouterMailCc: '/mailscc/ajouter',
  modifierMailCc: '/mailscc/modifier/:id',

  stats: '/tablesreference/statistiques',
  typeAdministrationAdminDgalnHabilitation:
    '/tablesreference/statistiques/groupestypeadministrationadmindgalnhabilitation',
  createTypeAdministrationAdminDgalnHabilitation:
    '/tablesreference/statistiques/groupestypeadministrationadmindgalnhabilitation/ajouter',
  updateTypeAdministrationAdminDgalnHabilitation:
    '/tablesreference/statistiques/groupestypeadministrationadmindgalnhabilitation/modifier/:id',

  typeAdministrationAdminDgaln:
    '/tablesreference/statistiques/groupestypeadministrationadmindgaln',
  createTypeAdministrationAdminDgaln:
    '/tablesreference/statistiques/groupestypeadministrationadmindgaln/ajouter',
  updateTypeAdministrationAdminDgaln:
    '/tablesreference/statistiques/groupestypeadministrationadmindgaln/modifier/:id',

  typeAdministrationAdminDgpr:
    '/tablesreference/statistiques/groupestypeadministrationadmindgpr',
  createTypeAdministrationAdminDgpr:
    '/tablesreference/statistiques/groupestypeadministrationadmindgpr/ajouter',
  updateTypeAdministrationAdminDgpr:
    '/tablesreference/statistiques/groupestypeadministrationadmindgpr/modifier/:id',
};

export const lookupTablesRoutes = {
  tablesReference:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.tablesReference,
  tablesReferenceOnglet:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.tablesReferenceOnglet,
  tablesReferenceOngletSelect:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.tablesReferenceOngletSelect,

  tablesReferenceOngletSelectAction:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.tablesReferenceOngletSelectAction,
  tablesReferenceOngletSelectActionId:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.tablesReferenceOngletSelectActionId,

  agents: lookupTablesRoutesBaseName + lookupTablesRoutesRelative.agents,
  ajouterAgentStatus:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.ajouterAgentStatus,
  modifierAgentStatus:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.modifierAgentStatus,
  ajouterAgentStatusGroup:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.ajouterAgentStatusGroup,
  modifierAgentStatusGroup:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.modifierAgentStatusGroup,
  ajouterTypeAdministration:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.ajouterTypeAdministration,
  modifierTypeAdministration:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.modifierTypeAdministration,
  ajouterService:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.ajouterService,
  modifierService:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.modifierService,
  ajouterDominanteMetier:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.ajouterDominanteMetier,
  modifierDominanteMetier:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.modifierDominanteMetier,
  ajouterCursusAllege:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.ajouterCursusAllege,
  modifierCursusAllege:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.modifierCursusAllege,
  formations:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.formations,
  modifierCursus:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.modifierCursus,
  ajouterCursus:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.ajouterCursus,
  modifierModule:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.modifierModule,
  ajouterModule:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.ajouterModule,
  modifierOrganisme:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.modifierOrganisme,
  ajouterOrganisme:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.ajouterOrganisme,
  modifierLieu:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.modifierLieu,
  ajouterLieu:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.ajouterLieu,
  commissionnement:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.commissionnement,
  createHabilitation:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.createHabilitation,
  updateHabilitation:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.updateHabilitation,
  createZoneCommissionnement:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.createZoneCommissionnement,
  updateZoneCommissionnement:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.updateZoneCommissionnement,
  updateExpediteurBordereau:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.updateExpediteurBordereau,
  createExpediteurBordereau:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.createExpediteurBordereau,
  createDestinataireBordereau:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.createDestinataireBordereau,
  updateDestinataireBordereau:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.updateDestinataireBordereau,

  consulterMailCc:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.consulterMailCc,
  ajouterMailCc:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.ajouterMailCc,
  modifierMailCc:
    lookupTablesRoutesBaseName + lookupTablesRoutesRelative.modifierMailCc,

  createTypeAdministrationAdminDgalnHabilitation:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.createTypeAdministrationAdminDgalnHabilitation,
  createTypeAdministrationAdminDgaln:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.createTypeAdministrationAdminDgaln,
  createTypeAdministrationAdminDgpr:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.createTypeAdministrationAdminDgpr,

  updateTypeAdministrationAdminDgalnHabilitation:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.updateTypeAdministrationAdminDgalnHabilitation,
  updateTypeAdministrationAdminDgaln:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.updateTypeAdministrationAdminDgaln,
  updateTypeAdministrationAdminDgpr:
    lookupTablesRoutesBaseName +
    lookupTablesRoutesRelative.updateTypeAdministrationAdminDgpr,
};

export const agentsRoutesBaseName = '/agents';

export const agentRoutesRelative = {
  base: '/',
  list: '/liste',
  consulter: '/:id/consulteragent',
  consulterPostes: '/:id/consulterpostes',
  add: '/ajouteragent',
  addPoste: '/ajouterposteagent',
  poste: '/:id/consulterpostes',
  fichesPoste: '/fichesdeposte',
  listeFichesPoste: '/fichesdeposte/liste',
  agentsNonAffecte: '/listenonaffectes',
  listeAgentsNonAffecte: '/listenonaffectes/liste',
  arriveesAgents: '/lesarrivees',
  listeArriveesAgents: '/lesarrivees/liste',
  enregistrerArrivee: '/lesarrivees/enregistrer/:agentId',

  enregistrerDepart: '/:id/enregistrerdepart',
  modifierDepart: '/departs/modifier/:agentId',
  listeDepart: '/departs/liste',
  departs: '/departs',

  inscrireAutoFormation: '/:id/inscrireautoformation/:foadId',
  validerSuiviAutoformation: '/:id/validersuiviautoformation/:foadId',

  consulterModulesParCursus: '/:agentId/consulterformations/cursus/:cursusId',
  selectionnerSession:
    '/consultermodulescursus/:cursusId/selectionnersession/:moduleId',
};

export const agentRoutes = {
  base: agentsRoutesBaseName + agentRoutesRelative.base,
  list: agentsRoutesBaseName + agentRoutesRelative.list,
  consulter: agentsRoutesBaseName + agentRoutesRelative.consulter,
  consulterPostes: agentsRoutesBaseName + agentRoutesRelative.consulterPostes,
  add: agentsRoutesBaseName + agentRoutesRelative.add,
  addPoste: agentsRoutesBaseName + agentRoutesRelative.addPoste,
  poste: agentsRoutesBaseName + agentRoutesRelative.poste,
  listeFichesPoste: agentsRoutesBaseName + agentRoutesRelative.listeFichesPoste,
  listeAgentsNonAffecte:
    agentsRoutesBaseName + agentRoutesRelative.listeAgentsNonAffecte,
  listeArriveesAgents:
    agentsRoutesBaseName + agentRoutesRelative.listeArriveesAgents,
  enregistrerArrivee:
    agentsRoutesBaseName + agentRoutesRelative.enregistrerArrivee,
  enregistrerDepart:
    agentsRoutesBaseName + agentRoutesRelative.enregistrerDepart,
  modifierDepart: agentsRoutesBaseName + agentRoutesRelative.modifierDepart,
  listeDepart: agentsRoutesBaseName + agentRoutesRelative.listeDepart,
  inscrireAutoFormation:
    agentsRoutesBaseName + agentRoutesRelative.inscrireAutoFormation,
  validerSuiviAutoformation:
    agentsRoutesBaseName + agentRoutesRelative.validerSuiviAutoformation,
  consulterModulesParCursus:
    agentsRoutesBaseName + agentRoutesRelative.consulterModulesParCursus,
  selectionnerSession:
    agentsRoutesBaseName + agentRoutesRelative.selectionnerSession,
};

export const agentFicheRoutesBaseName = '/agents/:agentId';

export const agentFicheRoutesRelative = {
  consulterformations: '/consulterformations',
  consultercommissionnements: '/consultercommissionnements',
  actionCommissionnement: '/consultercommissionnements/:action',
  consulterhistoriqueformations: '/consulterformations/consulterhistorique',
  consulterModulesParCursus: '/consulterformations/cursus/:cursusId',
  gestionModuleParCursus:
    '/consulterformations/cursus/:cursusId/module/:moduleId/:action',
  consulterDemandeDeTransfert:
    '/consulterformations/cursus/:cursusId/module/:moduleId/transfert/:inscriptionId',
  consulterhabilitations: '/consulterhabilitations',
  consulterNotes: '/consulternotes',
  validerHabilitationAgent: '/consulterhabilitations/valider',
  gestionHabilitationAgent: '/consulterhabilitations/:action',
};

export const agentFicheRoutes = {
  consulterformations:
    agentFicheRoutesBaseName + agentFicheRoutesRelative.consulterformations,
  consultercommissionnements:
    agentFicheRoutesBaseName +
    agentFicheRoutesRelative.consultercommissionnements,
  actionCommissionnement:
    agentFicheRoutesBaseName + agentFicheRoutesRelative.actionCommissionnement,
  consulterhistoriqueformations:
    agentFicheRoutesBaseName +
    agentFicheRoutesRelative.consulterhistoriqueformations,
  consulterModulesParCursus:
    agentFicheRoutesBaseName +
    agentFicheRoutesRelative.consulterModulesParCursus,
  gestionModuleParCursus:
    agentFicheRoutesBaseName + agentFicheRoutesRelative.gestionModuleParCursus,
  consulterDemandeDeTransfert:
    agentFicheRoutesBaseName +
    agentFicheRoutesRelative.consulterDemandeDeTransfert,
  consulterhabilitations:
    agentFicheRoutesBaseName + agentFicheRoutesRelative.consulterhabilitations,
  consulterNotes:
    agentFicheRoutesBaseName + agentFicheRoutesRelative.consulterNotes,
  validerHabilitationAgent:
    agentFicheRoutesBaseName +
    agentFicheRoutesRelative.validerHabilitationAgent,
  gestionHabilitationAgent:
    agentFicheRoutesBaseName +
    agentFicheRoutesRelative.gestionHabilitationAgent,
};

export const aTraiterRoutesBaseName = '/atraiter';

export const aTraiterRoutesRelative = {
  base: '/',
  consulterDemandesCreationAgent: '/consulterdemandescreationagent',
  validerdemandecreationagent: '/validerdemandecreationagent/:agentId',
  consulterRefusCreationAgent: '/consulterrefuscreationagent',
  consulterArriveesProgrammees: '/consulterarriveesprogrammees',
  modifierCreationAgentDonneesAgent:
    '/consulterrefuscreationagent/modifiercreationagent/donneesagent/:agentId',
  modifierCreationAgentDonneesPoste:
    '/consulterrefuscreationagent/modifiercreationagent/donneesposte/:agentId',
  consulterPrisesDePoste: '/consulterprisesdeposte',
  consulterSemaineConsolidation: '/consultersemaineconsolidation',
  consulterModuleAgricoleAInscrire: '/consultermoduleagricoleainscrire',
  consulterModuleTutoratAInscrire: '/consultermoduletutoratainscrire',
  consulterAgentsHabilitables: '/consulteragentshabilitables',
  consulterAutoFormationsAInscrire: '/consulterautoformationsainscrire',
  consulterAutoFormations: '/consulterautoformations',

  agentsAInscrire: '/consulteragentsainscrire',
  consulterTransfertsRefuses: '/consultertransfertsrefuses',
  consulterDemandesInscriptions: '/consulterdemandesinscriptions',
  consulterInscriptionsRefusees: '/consulterinscriptionsrefusees',
  consulterTransfertsSessionDemandes: '/consultertransfertssessiondemandes',
  consulterDemandesDeTransfert: '/consulterdemandesdetransfert',
  validerDemandesDeTransfert: '/consulterdemandesdetransfert/:inscriptionId',
  consulterCommissionnementsAProposer: '/consultercommissionnementsaproposer',
  consulterCommissionnementsChangementAProposer:
    '/consultercommissionnementschangementaproposer',
  consulterCommissionnementsCartesAEditer:
    '/consultercommissionnementscartesaediter',
  consulterCommissionnementsRefuses: '/consultercommissionnementsrefuses',
  consulterCommissionnementsChangementRefuses:
    '/consultercommissionnementschangementrefuses',
  proposerCommissionnement:
    '/consultercommissionnementsaproposer/proposer/:agentId',
  proposerCommissionnementChangement:
    '/consultercommissionnementschangementaproposer/proposer/:agentId',
  consulterPropositionsCommissionnement:
    '/consulterpropositionscommissionnement',
  consulterPropositionsCommissionnementChangement:
    '/consulterpropositionscommissionnementchangement',
  validerPropositionCommissionnementChangement:
    '/consulterpropositionscommissionnementchangement/valider/:agentId/:actionId',
  validerPropositionCommissionnement:
    '/consulterpropositionscommissionnement/valider/:agentId/:actionId',
  consulterPropositionsChangementZone: '/consulterpropositionschangementzone',
  validerPropositionsChangementZone:
    '/consulterpropositionschangementzone/valider/:agentId/:actionId',
  consulterPropositionsDecommissionnement:
    '/consulterpropositionsdecommissionnement',
  validerPropositionDecommissionnement:
    '/consulterpropositionsdecommissionnement/valider/:agentId/:actionId',
  consulterPrestationDeSermentARenseigner:
    '/consulterprestationssermentarenseigner',
  renseignerPrestationDeSerment:
    '/consulterprestationssermentarenseigner/renseigner/:agentId',
  consulterPrestationsSermentAValider: '/consulterprestationssermentavalider',
  validerPrestationsSerment:
    '/consulterprestationssermentavalider/valider/:agentId',
  consulterPrestationsDeSermentRefusees:
    '/consulterprestationsdesermentrefusees',
  renseignerPrestationsDeSermentRefusees:
    '/consulterprestationsdesermentrefusees/renseigner/:agentId',
  consulterChangementDeZoneRefuses: '/consulterchangementdezonerefuses',
  consulterChangementDeServiceRefuses: '/consulterchangementdeservicerefuses',
  consulterEditionsCartesRefusees: '/consultereditionscartesrefusees',
  modifierChangementDeZone:
    '/commissionnement/proposerchangementdezone/:agentId',
  consulterDecommissionnementsRefuses: '/consulterdecommissionnementsrefuses',
  consulterCartesARenouveler: '/consultercartesarenouveler',
  validerRenouvellementCarte: '/consultercartesarenouveler/:agentId/:carteId',
  modifierDecommissionnement:
    '/commissionnement/proposerdecommissionnement/:agentId',
  consulterCartesARenouvelerRapidement: '/consultercartesarenouvelerrapidement',
  consulterCartesADetruire: '/consultercartesadetruire',
  consulterBordereauxAReceptionner: '/consulterbordereauxareceptionner',
  consulterBordereauxNonReceptionnes: '/consulterbordereauxnonreceptionnes',
  validerCarteADetruire: '/consultercartesadetruire/:agentId/:carteId',
  consulterAgentsAuMoinsUneNote: '/consulteragentsaumoinsunenote',
};

export const aTraiter = {
  base: aTraiterRoutesBaseName + aTraiterRoutesRelative.base,
  consulterDemandesCreationAgent:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterDemandesCreationAgent,
  validerdemandecreationagent:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.validerdemandecreationagent,
  consulterRefusCreationAgent:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.consulterRefusCreationAgent,
  consulterArriveesProgrammees:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterArriveesProgrammees,
  modifierCreationAgentDonneesAgent:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.modifierCreationAgentDonneesAgent,
  modifierCreationAgentDonneesPoste:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.modifierCreationAgentDonneesPoste,
  consulterPrisesDePoste:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.consulterPrisesDePoste,
  consulterSemaineConsolidation:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterSemaineConsolidation,
  consulterModuleAgricoleAInscrire:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterModuleAgricoleAInscrire,
  consulterModuleTutoratAInscrire:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterModuleTutoratAInscrire,

  consulterAgentsHabilitables:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.consulterAgentsHabilitables,

  consulterAutoFormationsAInscrire:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterAutoFormationsAInscrire,
  consulterAutoFormations:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.consulterAutoFormations,
  agentsAInscrire:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.agentsAInscrire,
  consulterTransfertsRefuses:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.consulterTransfertsRefuses,
  consulterDemandesInscriptions:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterDemandesInscriptions,
  consulterInscriptionsRefusees:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterInscriptionsRefusees,
  consulterTransfertsSessionDemandes:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterTransfertsSessionDemandes,
  consulterDemandesDeTransfert:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterDemandesDeTransfert,
  consulterPropositionsCommissionnement:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterPropositionsCommissionnement,
  consulterPropositionsCommissionnementChangement:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterPropositionsCommissionnementChangement,
  consulterPropositionsChangementZone:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterPropositionsChangementZone,
  validerPropositionsChangementZone:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.validerPropositionsChangementZone,
  consulterPropositionsDecommissionnement:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterPropositionsDecommissionnement,
  validerPropositionDecommissionnement:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.validerPropositionDecommissionnement,
  validerDemandesDeTransfert:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.validerDemandesDeTransfert,
  consulterCommissionnementsAProposer:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterCommissionnementsAProposer,
  consulterCommissionnementsChangementAProposer:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterCommissionnementsChangementAProposer,
  consulterCommissionnementsRefuses:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterCommissionnementsRefuses,
  consulterCommissionnementsChangementRefuses:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterCommissionnementsChangementRefuses,
  consulterCommissionnementsCartesAEditer:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterCommissionnementsCartesAEditer,
  proposerCommissionnement:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.proposerCommissionnement,
  proposerCommissionnementChangement:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.proposerCommissionnementChangement,
  validerPropositionCommissionnement:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.validerPropositionCommissionnement,
  validerPropositionCommissionnementChangement:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.validerPropositionCommissionnementChangement,
  consulterPrestationDeSermentARenseigner:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterPrestationDeSermentARenseigner,

  renseignerPrestationDeSerment:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.renseignerPrestationDeSerment,
  consulterPrestationsSermentAValider:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterPrestationsSermentAValider,
  validerPrestationsSerment:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.validerPrestationsSerment,
  consulterPrestationsDeSermentRefusees:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterPrestationsDeSermentRefusees,
  renseignerPrestationsDeSermentRefusees:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.renseignerPrestationsDeSermentRefusees,

  consulterChangementDeZoneRefuses:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterChangementDeZoneRefuses,
  consulterChangementDeServiceRefuses:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterChangementDeServiceRefuses,
  consulterEditionsCartesRefusees:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterEditionsCartesRefusees,
  modifierChangementDeZone:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.modifierChangementDeZone,
  consulterDecommissionnementsRefuses:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterDecommissionnementsRefuses,
  consulterCartesARenouveler:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.consulterCartesARenouveler,
  proposerRenouvellementCarte:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.validerRenouvellementCarte,
  modifierDecommissionnement:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.modifierDecommissionnement,
  consulterCartesARenouvelerRapidement:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterCartesARenouvelerRapidement,
  consulterCartesADetruire:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.consulterCartesADetruire,
  consulterBordereauxAReceptionner:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterBordereauxAReceptionner,
  consulterBordereauxNonReceptionnes:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterBordereauxNonReceptionnes,
  validerCarteADetruire:
    aTraiterRoutesBaseName + aTraiterRoutesRelative.validerCarteADetruire,
  consulterAgentsAuMoinsUneNote:
    aTraiterRoutesBaseName +
    aTraiterRoutesRelative.consulterAgentsAuMoinsUneNote,
};

export const enCoursDeTraitementRoutesBaseName = '/encoursdetraitement';

export const enCoursDeTraitementRoutesRelative = {
  base: '/',
  agentsCreationsEnAttente: '/agentscreationsenattente',
  inscriptionsEnAttente: '/inscriptionsenattente',
  demandesDeTransfert: '/demandesdetransfert',
  commissionnementsPropositions: '/commissionnementspropositions',
  commissionnementsEnAttenteArrete: '/commissionnementsenattentearrete',
  commissionnementsCartesAEditer: '/commissionnementscartesaediter',
  changementsZoneEnAttente: '/changementszoneenattente',
  changementsCommissionnementEnAttente: '/changementscommissionnementenattente',
  prestationsEnAttente: '/prestationsenattente',
  decommissionnementsEnAttenteArrete: '/decommissionnementsenattentearrete',
};

export const enCoursDeTraitement = {
  base:
    enCoursDeTraitementRoutesBaseName + enCoursDeTraitementRoutesRelative.base,
  agentsCreationsEnAttente:
    enCoursDeTraitementRoutesBaseName +
    enCoursDeTraitementRoutesRelative.agentsCreationsEnAttente,
  inscriptionsEnAttente:
    enCoursDeTraitementRoutesBaseName +
    enCoursDeTraitementRoutesRelative.inscriptionsEnAttente,
  demandesDeTransfert:
    enCoursDeTraitementRoutesBaseName +
    enCoursDeTraitementRoutesRelative.demandesDeTransfert,
  commissionnementsPropositions:
    enCoursDeTraitementRoutesBaseName +
    enCoursDeTraitementRoutesRelative.commissionnementsPropositions,
  commissionnementsEnAttenteArrete:
    enCoursDeTraitementRoutesBaseName +
    enCoursDeTraitementRoutesRelative.commissionnementsEnAttenteArrete,
  commissionnementsCartesAEditer:
    enCoursDeTraitementRoutesBaseName +
    enCoursDeTraitementRoutesRelative.commissionnementsCartesAEditer,
  changementsZoneEnAttente:
    enCoursDeTraitementRoutesBaseName +
    enCoursDeTraitementRoutesRelative.changementsZoneEnAttente,
  changementsCommissionnementEnAttente:
    enCoursDeTraitementRoutesBaseName +
    enCoursDeTraitementRoutesRelative.changementsCommissionnementEnAttente,
  prestationsEnAttente:
    enCoursDeTraitementRoutesBaseName +
    enCoursDeTraitementRoutesRelative.prestationsEnAttente,
  decommissionnementsEnAttenteArrete:
    enCoursDeTraitementRoutesBaseName +
    enCoursDeTraitementRoutesRelative.decommissionnementsEnAttenteArrete,
};

export const posteRoutesRelative = {
  modifierArrivee: '/:id/modifierarrivee/:posteId',
  modifierProfilPoste: '/:id/modifierprofilposte/:posteId',
};

export const posteRoutes = {
  modifierArrivee: agentsRoutesBaseName + posteRoutesRelative.modifierArrivee,
  modifierProfilPoste:
    agentsRoutesBaseName + posteRoutesRelative.modifierProfilPoste,
};

export const formationsRoutesBaseName = '/formations';

export const formationsRoutesRelative = {
  base: '/',
  sessions: '/sessions',
  list: '/sessions/liste',
  ajouter: '/sessions/ajouter',
  modifier: '/sessions/modifier/:sessionId',
  consulter: '/sessions/consulter/:sessionId',
  inscriptions: '/sessions/inscriptions/:inscriptionId',
};

export const formationsRoutes = {
  base: formationsRoutesBaseName + formationsRoutesRelative.base,
  sessions: formationsRoutesBaseName + formationsRoutesRelative.sessions,
  list: formationsRoutesBaseName + formationsRoutesRelative.list,
  ajouter: formationsRoutesBaseName + formationsRoutesRelative.ajouter,
  modifier: formationsRoutesBaseName + formationsRoutesRelative.modifier,
  consulter: formationsRoutesBaseName + formationsRoutesRelative.consulter,
  inscriptions:
    formationsRoutesBaseName + formationsRoutesRelative.inscriptions,
};

export const sessionsRoutesRelative = {
  validerInscriptionSession:
    formationsRoutesRelative.inscriptions + '/validerdemandeinscription',
  modifierRelationAgent:
    formationsRoutesRelative.inscriptions + '/modifierrelationagent/:sessionId',
};

export const sessionsRoutes = {
  validerInscriptionSession:
    formationsRoutes.inscriptions + '/validerdemandeinscription',
  modifierRelationAgent:
    formationsRoutes.inscriptions + '/modifierrelationagent/:sessionId',
};

export const archivesRoutesBaseName = '/archives';

export const archivesRoutesRelative = {
  base: '/',
  agentRetraites: '/agents/retraites',
  agentRetraitesList: '/agents/retraites/liste',
  agentRetraitesDetails: '/agents/retraites/:agentId/*',
  agentConsulterRetraites: '/agents/retraites/:agentId/consulteragent',
  agentArchives: '/agents/archives',
  agentArchivesList: '/agents/archives/liste',
  agentArchivesDetails: '/agents/archives/:agentId/*',
  agentConsulterArchives: '/agents/archives/:agentId/consulteragent',
};

export const archivesRoutes = {
  base: archivesRoutesBaseName + archivesRoutesRelative.base,
  agentRetraites:
    archivesRoutesBaseName + archivesRoutesRelative.agentRetraites,
  agentRetraitesList:
    archivesRoutesBaseName + archivesRoutesRelative.agentRetraitesList,
  agentRetraitesDetails:
    archivesRoutesBaseName + archivesRoutesRelative.agentRetraitesDetails,
  agentConsulterRetraites:
    archivesRoutesBaseName + archivesRoutesRelative.agentConsulterRetraites,
  agentArchives: archivesRoutesBaseName + archivesRoutesRelative.agentArchives,
  agentArchivesList:
    archivesRoutesBaseName + archivesRoutesRelative.agentArchivesList,
  agentArchivesDetails:
    archivesRoutesBaseName + archivesRoutesRelative.agentArchivesDetails,
  agentConsulterArchives:
    archivesRoutesBaseName + archivesRoutesRelative.agentConsulterArchives,
};

export const commissionnementsRoutesBaseName = '/commissionnements';

export const commissionnementsRoutesRelative = {
  base: '/',
  agentsHabilitesList: '/agents/habilites/liste',
  agentsCommissionnesList: '/agents/commissionnes/liste',
  arretesNominationBaseName: '/arretesnomination',
  arretesNomination: '/arretesnomination/:action',
  arretesNominationNotifierAgents:
    '/arretesnomination/:arreteNominationId/notifieragents',
  bordereauxCartesAEditer: '/bordereaux',
};

export const commissionnementsRoutes = {
  base: commissionnementsRoutesBaseName + commissionnementsRoutesRelative.base,
  agentsHabilitesList:
    commissionnementsRoutesBaseName +
    commissionnementsRoutesRelative.agentsHabilitesList,
  agentsCommissionnesList:
    commissionnementsRoutesBaseName +
    commissionnementsRoutesRelative.agentsCommissionnesList,
  arretesNomination:
    commissionnementsRoutesBaseName +
    commissionnementsRoutesRelative.arretesNomination,
  arretesNominationNotifierAgents:
    commissionnementsRoutesBaseName +
    commissionnementsRoutesRelative.arretesNominationNotifierAgents,
  bordereauxCartesAEditer:
    commissionnementsRoutesBaseName +
    commissionnementsRoutesRelative.bordereauxCartesAEditer,
};

export const suiviActivite = '/suiviactivite';

export const aTraiterSuperieurDeuxMoisRoutesBaseName =
  '/atraitersuperieurdeuxmois';

export const aTraiterSuperieurDeuxMoisRoutesRelative = {
  base: '/',
};

export const aTraiterSuperieurDeuxMois = {
  base:
    aTraiterSuperieurDeuxMoisRoutesBaseName +
    aTraiterSuperieurDeuxMoisRoutesRelative.base,
};

export const statistiquesRoutesBaseName = '/statistiques';

export const statistiquesRoutesRelative = {
  base: '/',
  agentsCommissionnes: '/agentscommissionnes',
  agentsCommissionnesList: '/agentscommissionnes/liste',
  agentsCommissionnesAnnee: '/agentscommissionnesannee',
  agentsCommissionnesAnneeList: '/agentscommissionnesannee/liste',
  agentsCommissionnesStatut: '/agentscommissionnesstatut',
  agentsCommissionnesStatutList: '/agentscommissionnesstatut/liste',
  agentsDecommissionnesAnnee: '/agentsdecommissionnesannee',
  agentsDecommissionnesAnneeList: '/agentsdecommissionnesannee/liste',
  agentsZoneChangeeAnnee: '/agentszonechangeeannee',
  agentsZoneChangeeAnneeList: '/agentszonechangeeannee/liste',
  agentsChangementCommissionnementAnnee:
    '/agentschangementcommissionnementannee',
  agentsChangementCommissionnementAnneeList:
    '/agentschangementcommissionnementannee/liste',
  agentsCommissionnesHabilitation: '/agentscommissionneshabilitation',
  agentsCommissionnesHabilitationList: '/agentscommissionneshabilitation/liste',
  agentsSuiviFormationsAnnee: '/agentssuiviformationsannee',
  agentsSuiviFormationsAnneeList: '/agentssuiviformationsannee/liste',
  agentsTBSuiviPDP: '/agentstbsuivipdp/',
  agentsTBSuiviPDPList: '/agentstbsuivipdp/liste',
  agentsSuiviRepartitionPDP: '/agentssuivirepartitionpdp',
  agentsSuiviRepartitionPDPList: '/agentssuivirepartitionpdp/liste',
  agentsNonInscritsAnnee: '/agentsnoninscritsannee',
  agentsNonInscritsAnneeList: '/agentsnoninscritsannee/liste',
};

export const statistiquesRoutes = {
  base: statistiquesRoutesBaseName + statistiquesRoutesRelative.base,
  agentsCommissionnes:
    statistiquesRoutesBaseName + statistiquesRoutesRelative.agentsCommissionnes,
  agentsCommissionnesList:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsCommissionnesList,
  agentsCommissionnesAnnee:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsCommissionnesAnnee,
  agentsCommissionnesAnneeList:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsCommissionnesAnneeList,
  agentsCommissionnesStatut:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsCommissionnesStatut,
  agentsCommissionnesStatutList:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsCommissionnesStatutList,
  agentsDecommissionnesAnnee:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsDecommissionnesAnnee,
  agentsDecommissionnesAnneeList:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsDecommissionnesAnneeList,
  agentsZoneChangeeAnnee:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsZoneChangeeAnnee,
  agentsZoneChangeeAnneeList:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsZoneChangeeAnneeList,
  agentsChangementCommissionnementAnnee:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsChangementCommissionnementAnnee,
  agentsChangementCommissionnementAnneeList:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsChangementCommissionnementAnneeList,
  agentsCommissionnesHabilitation:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsCommissionnesHabilitation,
  agentsCommissionnesHabilitationList:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsCommissionnesHabilitationList,
  agentsSuiviFormationsAnnee:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsSuiviFormationsAnnee,
  agentsSuiviFormationsAnneeList:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsSuiviFormationsAnneeList,
  agentsTBSuiviPDP:
    statistiquesRoutesBaseName + statistiquesRoutesRelative.agentsTBSuiviPDP,
  agentsTBSuiviPDPList:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsTBSuiviPDPList,
  agentsSuiviRepartitionPDP:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsSuiviRepartitionPDP,
  agentsSuiviRepartitionPDPList:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsSuiviRepartitionPDPList,
  agentsNonInscritsAnnee:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsNonInscritsAnnee,
  agentsNonInscritsAnneeList:
    statistiquesRoutesBaseName +
    statistiquesRoutesRelative.agentsNonInscritsAnneeList,
};

export const mentionsLegalesBaseName = '/mentions_legales';
export const accessibiliteBaseName = '/accessibilite';
export const sitemapBaseName = '/sitemap';

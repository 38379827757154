import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonGroup, Button } from '@dataesr/react-dsfr';

import ConsulterCommissionnementsEnAttenteArreteTable from './ConsulterCommissionnementsEnAttenteArreteTable';
import { enCoursDeTraitement } from '../../../configs/routes';
import { arretesRoutesAPI } from '../../../configs/routesAPI';
import axios from 'axios';
import TechnicalError from '../../../components/shared/technicalError/TechnicalError';

export default function PagesConsulterCommissionnementsEnAttenteArrete() {
  let navigate = useNavigate();
  const [commissionnements, setCommissionnements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(arretesRoutesAPI.getCommissionnementsEnAttente)
      .then((res) => setData(res.data.data))
      .catch((error) => {
        setApiError(error);
        setCommissionnements([]);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const setData = (agents) => {
    setCommissionnements(agents);
    setIsLoading(false);
  };

  return (
    <>
      {!isLoading ? <TechnicalError error={apiError} /> : <></>}
      <section className="fr-app-section">
        <ConsulterCommissionnementsEnAttenteArreteTable
          commissionnements={commissionnements}
          isLoading={isLoading}
        ></ConsulterCommissionnementsEnAttenteArreteTable>

        <div style={{ marginTop: '1.25rem' }}>
          <ButtonGroup isEquisized align="center" isInlineFrom="md">
            <Button
              onClick={() => navigate(enCoursDeTraitement.base)}
              secondary
            >
              Retour à la liste
            </Button>
          </ButtonGroup>
        </div>
      </section>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
  Text,
} from '@dataesr/react-dsfr';
import UserService from '../../services/user.service';
import axios from 'axios';
import { userRoutesAPI } from '../../configs/routesAPI';
import DernieresFichesConsulteesService from '../../services/dernieresfichesconsultees.service';
import LocalStorageService from '../../services/localstorage.service';
import FormHelper from '../../helpers/FormHelper';
import LoaderCommissionnement from '../../components/shared/loaders/LoaderCommissionnement';
import { roleEnum } from '../../enums/RoleEnum';

export default function SwitchProfilForm({ originalProfil }) {
  // Formulaire à soumettre
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  // listes
  const [regions, setRegions] = useState([]);
  const [departements, setDepartements] = useState([]);
  const [applications, setApplications] = useState([]);
  const [roles, setRoles] = useState([]);
  const [typesCommissionnement, setTypesCommissionnement] = useState([]);
  const [organismesGestionnaires, setOrganismesGestionnaires] = useState([]);
  const [sousOrganismeType, setSousOrganismeType] = useState();
  const [sousOrganismes, setSousOrganismes] = useState([]);

  const [sousOrganismeRequired, setSousOrganismeRequired] = useState(true);

  // selected Role Enum
  const [selectedRoleEnum, setSelectedRoleEnum] = useState(null);

  // données profils
  const [profilData, setProfilData] = useState();
  // const [profilDataStepOne, setProfilDataStepOne] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initRequest = [
      axios.get('/region'),
      axios.get('/departement'),
      axios.get(userRoutesAPI.getProfilDataStepOne),
    ];
    axios
      .all(initRequest)
      .then(([apiRegions, apiDepartements, dataStepOne]) => {
        setRegions(apiRegions.data.data);
        setDepartements(apiDepartements.data.data);
        profilDataStepOneReceived(dataStepOne.data.data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const profilDataStepOneReceived = (profilDataStepOneReceived) => {
    setApplications(profilDataStepOneReceived.application_contexts);
  };

  const applicationChanged = (e) => {
    // On reset les champs du formulaire
    setValue('roleId', null);
    setValue('typeCommissionnementId', null);
    setValue('organismeGestionnaireId', null);
    setValue('sousOrganismeId', null);
    setSelectedRoleEnum(null);
    // On ré-initialise la liste des rôles
    const selectedApplication = applications.find(
      (app) => app.id.toString() === e.target.value
    );
    if (selectedApplication) {
      delete errors.sousOrganismeId;
      setValue('applicationContextId', e.target.value.toString());
      setRoles(selectedApplication.roles);
    } else {
      setValue('applicationContextId', null);
      setRoles([]);
    }

    // On réinitialise le contenu des listes déroulante pour recommencer le parcours
    setTypesCommissionnement([]);
    setOrganismesGestionnaires([]);
    setSousOrganismeType(null);
    setSousOrganismes([]);
  };

  const roleChanged = (e) => {
    delete errors.roleId;

    setValue('typeCommissionnementId', null);
    setValue('organismeGestionnaireId', null);
    setValue('sousOrganismeId', null);

    // On reinitialise les listes
    setTypesCommissionnement([]);
    setOrganismesGestionnaires([]);
    setSousOrganismeType(null);
    setSousOrganismes([]);
    setProfilData(null);

    const selectedRole = roles.find(
      (role) => role.id.toString() === e.target.value
    );
    // Si j'ai selectionné un role
    if (selectedRole) {
      const selectedApplication = applications.find(
        (app) => app.id.toString() === getValues('applicationContextId')
      );
      setSousOrganismeRequired(
        [roleEnum.CONSULTANT].includes(selectedRole.name) &&
          [roleEnum.ADMINISTRATEUR_GENERAL].includes(originalProfil.role) &&
          selectedApplication.name === 'EAU_ET_NATURE'
      );
      setValue('roleId', e.target.value);
      setSelectedRoleEnum(selectedRole.name);
      // Si ce role n'est pas ADMINISTRATEUR_GENERAL
      if (selectedRole.name !== roleEnum.ADMINISTRATEUR_GENERAL) {
        fetchProfilDataStepTwo(
          getValues('applicationContextId'),
          e.target.value
        );
      }
    } else {
      setValue('roleId', null);
      setSelectedRoleEnum(null);
    }
  };

  const typeCommissionnementChanged = (e) => {
    delete errors.typeCommissionnementId;

    setValue('organismeGestionnaireId', null);
    setValue('sousOrganismeId', null);

    setSousOrganismeType(null);
    setSousOrganismes([]);

    const selectedTypeCommissionnement = typesCommissionnement.find(
      (tc) => tc.id.toString() === e.target.value
    );
    if (selectedTypeCommissionnement) {
      setOrganismesGestionnaires(
        selectedTypeCommissionnement.organismes_gestionnaires
      );
    } else {
      setOrganismesGestionnaires([]);
    }
  };

  const organismeGestionnaireChanged = (e) => {
    delete errors.organismeGestionnaireId;

    setSousOrganismeType(null);
    setSousOrganismes([]);
    const selectedOrganismeGestionnaire = organismesGestionnaires.find(
      (orgGest) => orgGest.id.toString() === e.target.value
    );
    if (selectedOrganismeGestionnaire) {
      setSousOrganismeType(selectedOrganismeGestionnaire.sous_organisme_type);
      switch (selectedOrganismeGestionnaire.sous_organisme_type) {
        case 'region':
          setSousOrganismes(regions);
          break;
        case 'departement':
          setSousOrganismes(departements);
          break;
        default:
          setSousOrganismes([]);
      }
    } else {
      setSousOrganismeType(null);
      setSousOrganismes([]);
    }
  };

  const fetchProfilDataStepTwo = (applicationContextId, roleId) => {
    axios
      .get(
        userRoutesAPI.getProfilDataStepTwo
          .replace(':applicationContextId', applicationContextId)
          .replace(':roleId', roleId)
      )
      .then((res) => {
        const profilDataReceived = res.data.data;
        setProfilData(profilDataReceived);
        setTypesCommissionnement(profilDataReceived.types_commissionnement);
        console.log(profilData);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  };

  const onSubmit = (data, event) => {
    event.preventDefault();
    axios
      .post(userRoutesAPI.switchProfil, {
        application_context_id: data.applicationContextId,
        role_id: data.roleId,
        types_commissionnement_ids: data.typeCommissionnementId
          ? typesCommissionnement.find(
              (tc) => tc.id.toString() === data.typeCommissionnementId
            ).ids
          : [],
        organisme_gestionnaire_id: data.organismeGestionnaireId,
        sous_organisme_type: canChooseSousOrganisme
          ? sousOrganismeType
          : originalProfil.sous_organisme_type,
        sous_organisme_id: canChooseSousOrganisme
          ? data.sousOrganismeId
          : originalProfil.sous_organisme_id,
      })
      .then(function (response) {
        if (200 === response.status) {
          UserService.setUser(response.data.data);
          DernieresFichesConsulteesService.reset();
          LocalStorageService.cleanSearch();
          // we need to update the colors ; navigate() will not refresh the parent headers
          window.location.href = UserService.getDefaultRoute();
          return;
        }
      })
      .catch(function (error) {
        console.log({ error }); // TODO handle error
      });
  };

  const canChooseSousOrganisme =
    sousOrganismeType &&
    (null === originalProfil.sous_organisme_id ||
      [roleEnum.ADMINISTRATEUR_GENERAL, roleEnum.ADMINISTRATEUR].includes(
        originalProfil.role
      ));

  const organismeGestionnaireRequired = [
    roleEnum.GESTIONNAIRE,
    roleEnum.CONSULTANT,
  ].includes(selectedRoleEnum);

  return isLoading ? (
    <LoaderCommissionnement />
  ) : (
    <form className="fr-app-form" onSubmit={handleSubmit(onSubmit)}>
      <Text size="xs" className="remark roboto-italic">
        Les champs possédant un <span className="error">*</span> sont
        obligatoires
      </Text>
      <Container fluid>
        <Row>
          <Col n="6">
            <div className={'required-field'}>
              <label
                className="fr-label "
                htmlFor="applicationId"
                aria-describedby=""
              >
                Application
              </label>
              <select
                id="applicationId"
                className="fr-select required-field"
                aria-required="true"
                {...register('applicationContextId', { required: true })}
                onChange={(e) => applicationChanged(e)}
              >
                <option key="" value="">
                  Sélectionner
                </option>
                {applications.map((app) => {
                  return (
                    <option key={app.id} value={app.id}>
                      {app.label}
                    </option>
                  );
                })}
              </select>
              {FormHelper.displayErrHtml(errors.applicationContextId)}
            </div>
          </Col>

          <Col n="6">
            <div className={'required-field'}>
              <label className="fr-label" htmlFor="roleId" aria-describedby="">
                Rôle
              </label>
              <select
                className="fr-select required-field"
                id="roleId"
                aria-required="true"
                {...register('roleId', { required: true })}
                disabled={!getValues('applicationContextId')}
                onChange={(e) => roleChanged(e)}
              >
                <option key="" value="">
                  Sélectionner
                </option>
                {roles.map((role) => {
                  return (
                    <option key={role.id} value={role.id}>
                      {role.label}
                    </option>
                  );
                })}
              </select>
              {FormHelper.displayErrHtml(errors.roleId)}
            </div>
          </Col>

          {selectedRoleEnum &&
          ![roleEnum.ADMINISTRATEUR_GENERAL].includes(selectedRoleEnum) ? (
            <>
              <Col n="6">
                <div className={'required-field'}>
                  <label
                    className="fr-label"
                    htmlFor="typeCommissionnementId"
                    aria-describedby=""
                  >
                    Type de commissionnement
                  </label>
                  <select
                    id="typeCommissionnementId"
                    className="fr-select required-field"
                    {...register('typeCommissionnementId', { required: true })}
                    aria-required="true"
                    onChange={(e) => typeCommissionnementChanged(e)}
                  >
                    <option key="" value="">
                      Sélectionner
                    </option>
                    {typesCommissionnement.map((typeCommissionnement) => {
                      return (
                        <option
                          key={typeCommissionnement.id}
                          value={typeCommissionnement.id}
                        >
                          {typeCommissionnement.label}
                        </option>
                      );
                    })}
                  </select>
                  {FormHelper.displayErrHtml(errors.typeCommissionnementId)}
                </div>
              </Col>
              {[roleEnum.GESTIONNAIRE, roleEnum.CONSULTANT].includes(
                selectedRoleEnum
              ) ? (
                <>
                  <Col n="6">
                    <div
                      className={
                        organismeGestionnaireRequired ? 'required-field' : ''
                      }
                    >
                      <label
                        className="fr-label"
                        htmlFor="organismeGestionnaireId"
                        aria-describedby=""
                      >
                        Organisme gestionnaire
                      </label>
                      <select
                        className="fr-select"
                        id="organismeGestionnaireId"
                        {...register('organismeGestionnaireId', {
                          required: true,
                        })}
                        aria-required="true"
                        onChange={(e) => organismeGestionnaireChanged(e)}
                      >
                        <option key="" value="">
                          Sélectionner
                        </option>
                        {organismesGestionnaires.map((orgGest) => {
                          return (
                            <option key={orgGest.id} value={orgGest.id}>
                              {orgGest.label}
                            </option>
                          );
                        })}
                      </select>
                      {FormHelper.displayErrHtml(
                        errors.organismeGestionnaireId
                      )}
                    </div>
                  </Col>
                  {canChooseSousOrganisme ? (
                    <>
                      {sousOrganismeRequired ? (
                        <Col n="6">
                          <div className={'required-field'}>
                            <label
                              className="fr-label"
                              htmlFor="sousOrganismeId"
                              aria-describedby=""
                            >
                              Sous-organisme
                            </label>
                            <select
                              id="sousOrganismeId"
                              className={'fr-select required-field'}
                              {...register('sousOrganismeId', {
                                required: true,
                              })}
                              aria-required={true}
                            >
                              <option key="" value="">
                                Sélectionner
                              </option>
                              {sousOrganismes.map((sousOrg) => {
                                return (
                                  <option key={sousOrg.id} value={sousOrg.id}>
                                    {sousOrg.label}
                                  </option>
                                );
                              })}
                            </select>
                            {FormHelper.displayErrHtml(errors.sousOrganismeId)}
                          </div>
                        </Col>
                      ) : (
                        <Col n="6">
                          <div>
                            <label
                              className="fr-label"
                              htmlFor="sousOrganismeId"
                              aria-describedby=""
                            >
                              Sous-organisme
                            </label>
                            <select
                              id="sousOrganismeId"
                              className={'fr-select'}
                              {...register('sousOrganismeId', {
                                required: false,
                              })}
                              aria-required={false}
                            >
                              <option key="" value="">
                                Sélectionner
                              </option>
                              {sousOrganismes.map((sousOrg) => {
                                return (
                                  <option key={sousOrg.id} value={sousOrg.id}>
                                    {sousOrg.label}
                                  </option>
                                );
                              })}
                            </select>
                            {FormHelper.displayErrHtml(errors.sousOrganismeId)}
                          </div>
                        </Col>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Row>
      </Container>

      <ButtonGroup isEquisized align="center" isInlineFrom="md">
        <Button submit data-test-id="">
          Appliquer
        </Button>
      </ButtonGroup>
    </form>
  );
}

SwitchProfilForm.propTypes = {
  originalProfil: PropTypes.object,
};

import React, { useEffect, useState } from 'react';
import ApiService from './../../services/api.service.js';
import DateHelper from '../../helpers/DateHelper';
import { Button, ButtonGroup } from '@dataesr/react-dsfr';
import TableWithLoader from '../../components/shared/tables/TableWithLoader';
import { enCoursDeTraitement } from '../../configs/routes';
import { useNavigate } from 'react-router-dom';

export default function DemandesCreationAgentEnAttente() {
  const [demandesCreation, setDemandesCreation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    ApiService.fetchData('/atraiter/demande-creation', setData);
  }, []);

  const setData = (agents) => {
    setDemandesCreation(agents);
    setIsLoading(false);
  };

  const columns = [
    {
      name: 'date_demande_creation',
      label: 'Date demande',
      render: ({ date_demande_creation }) =>
        DateHelper.format(date_demande_creation),
    },
    {
      name: 'nom',
      label: 'Nom',
    },
    {
      name: 'prenom',
      label: 'Prénom',
    },
    {
      name: 'date_naissance',
      label: 'Date naissance',
      render: ({ date_naissance }) => DateHelper.format(date_naissance),
    },
    {
      name: 'email_professionnel',
      label: 'Adresse mail',
    },
    {
      name: 'service',
      label: 'Service',
    },
    {
      name: 'potential_duplicates_count',
      label: 'D',
      render: ({ potential_duplicates_count }) =>
        0 !== potential_duplicates_count ? potential_duplicates_count : '',
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    ApiService.fetchData('/atraiter/demande-creation', setDemandesCreation);
  }, []);

  return (
    <section className="fr-app-section">
      <div className="fr-app-table">
        <caption style={{ display: 'block' }}>
          Liste des demandes de création de dossiers d'agents
        </caption>
      </div>
      <TableWithLoader
        data={demandesCreation}
        columns={columns}
        isLoading={isLoading}
        rowKey="id"
        caption=""
        customClassName="creation-agent-tab no-caption"
      ></TableWithLoader>
      <div style={{ marginTop: '1.25rem' }}>
        <ButtonGroup isEquisized align="center" isInlineFrom="md">
          <Button onClick={() => navigate(enCoursDeTraitement.base)} secondary>
            Retour à la liste
          </Button>
        </ButtonGroup>
      </div>
    </section>
  );
}

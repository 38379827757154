import axios from 'axios';
import React, { useState, useEffect } from 'react';
import LoaderCommissionnement from '../../components/shared/loaders/LoaderCommissionnement';
import TechnicalError from '../../components/shared/technicalError/TechnicalError';
import { stats } from '../../configs/routesAPI';
import { saveAs } from 'file-saver';
import { Button, ButtonGroup, Container, Row } from '@dataesr/react-dsfr';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { localStorageSearchKey } from '../../configs/localStorageSearchKey';
import Period from '../../components/shared/selects/Period';
import FormHelper from '../../helpers/FormHelper';
import DateHelper from '../../helpers/DateHelper';

export default function PagesStatsAgentsTBSuiviPDPListe() {
  const [agentsTBSuiviPDP, setAgentsTBSuiviPDP] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const today = new Date();
  const dateDebut = '2019-01-01';
  const dateFin = today
    .toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .split('/')
    .reverse()
    .join('-');

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dateDebut: dateDebut,
      dateFin: dateFin,
    },
  });

  useEffect(() => {
    // Initialisation des valeurs du formulaires
    let inputParam = localStorage.getItem(
      localStorageSearchKey.searchStatistiquesPeriodListe
    );
    if (inputParam !== null) {
      inputParam = JSON.parse(inputParam);
      if (inputParam.dateDebut && inputParam.dateDebut !== null) {
        setValue('dateDebut', inputParam.dateDebut);
      }
      if (inputParam.dateFin && inputParam.dateFin !== null) {
        setValue('dateFin', inputParam.dateFin);
      }
    }
    getData({
      date_min: getValues('dateDebut'),
      date_max: getValues('dateFin'),
    });
  }, []);

  const getData = (params) => {
    localStorage.setItem(
      localStorageSearchKey.searchStatistiquesPeriodListe,
      JSON.stringify(params)
    );
    axios
      .get(stats.getAgentsTBSuiviPDP, { params })
      .then((response) => {
        setAgentsTBSuiviPDP(response.data.data);
        setIsLoading(false);
      })
      .catch((errors) => {
        setApiError(errors);
        if (errors.response.status === 422) {
          FormHelper.displayErr(errors.date_debut, 'dateDebut', setError);
          FormHelper.displayErr(errors.date_fin, 'dateFin', setError);
        }
        setIsLoading(false);
      });
  };

  const onSubmit = (data, event) => {
    setIsLoading(true);
    event.preventDefault();

    const params = {
      date_min: data.dateDebut,
      date_max: data.dateFin,
    };

    getData(params);
  };

  const downloadCsv = (event) => {
    event.preventDefault();

    const params = {
      date_min: getValues('dateDebut'),
      date_max: getValues('dateFin'),
    };

    axios({
      url: stats.exportAgentsTBSuiviPDP,
      params: params,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'text/csv;',
      });
      saveAs(blob, 'Tableau_bord_suivi_régional_prise_de_poste.csv');
    });
  };

  return (
    <div>
      {!isLoading ? <TechnicalError error={apiError} /> : <></>}

      <section className="fr-app-section">
        <form className="fr-app-form" onSubmit={handleSubmit(onSubmit)}>
          <Container fluid>
            <Row>
              <Period
                register={register}
                labelDebut="Date arrivée min"
                labelFin="Date arrivée max"
                errors={errors}
                minDate={dateDebut}
                maxDate={dateFin}
                withTooltip={true}
              ></Period>
            </Row>
            <Row>
              <Button submit>Rechercher</Button>
            </Row>
          </Container>
        </form>

        <div className="fr-table fr-app-table">
          <caption style={{ display: 'block' }}>
            Suivi régional des formations pour les agents arrivés en poste entre
            le {DateHelper.format(getValues('dateDebut'))} et le{' '}
            {DateHelper.format(getValues('dateFin'))}
          </caption>
          {isLoading ? (
            <LoaderCommissionnement className={'loader-center'} />
          ) : (
            <>
              <div className="tableContainer">
                <table className="table-stats-agents-tb-suivi-pdp no-caption">
                  <thead>
                    <tr className="fr-app-green-bg">
                      <th
                        scope="col"
                        key={'zone'}
                        style={{ borderRight: 'none' }}
                      ></th>
                      <th scope="col" key={'nb_agents'}></th>
                      <th colSpan={2} scope="col" key={'sm'}>
                        Semaine métier
                      </th>
                      <th colSpan={2} scope="col" key={'st'}>
                        Semaine technique
                      </th>
                      <th colSpan={2} scope="col" key={'sc'}>
                        Semaine consolidation
                      </th>
                      <th colSpan={2} scope="col" key={'sc'}>
                        Commissionnement
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" key="zone">
                        <div className="table-column-header">Zone</div>
                      </th>
                      <th scope="col" key="nb_agents">
                        <div className="table-column-header">Nb agents</div>
                      </th>
                      <th scope="col" key="sm_nb">
                        <div className="table-column-header">Nb</div>
                      </th>
                      <th scope="col" key="sm_delai_moyen">
                        <div className="table-column-header">Délai moyen</div>
                      </th>
                      <th scope="col" key="st_nb">
                        <div className="table-column-header">Nb</div>
                      </th>
                      <th scope="col" key="st_delai_moyen">
                        <div className="table-column-header">Délai moyen</div>
                      </th>
                      <th scope="col" key="sc_nb">
                        <div className="table-column-header">Nb</div>
                      </th>
                      <th scope="col" key="sc_delai_moyen">
                        <div className="table-column-header">Délai moyen</div>
                      </th>
                      <th scope="col" key="com_nb">
                        <div className="table-column-header">Nb</div>
                      </th>
                      <th scope="col" key="com_delai_moyen">
                        <div className="table-column-header">Délai moyen</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {agentsTBSuiviPDP.map((ligneSuiviPDP, i) => {
                      return (
                        <tr
                          key={i}
                          className={
                            i + 1 === agentsTBSuiviPDP.length
                              ? 'fr-app-green-bg'
                              : ''
                          }
                        >
                          <td>{ligneSuiviPDP.region_label}</td>
                          <td>{ligneSuiviPDP.nb_agents}</td>
                          <td>{ligneSuiviPDP.semaine_metier_nb_agents}</td>
                          <td>{ligneSuiviPDP.semaine_metier_delai_moyen}</td>
                          <td>{ligneSuiviPDP.semaine_technique_nb_agents}</td>
                          <td>{ligneSuiviPDP.semaine_technique_delai_moyen}</td>
                          <td>
                            {ligneSuiviPDP.semaine_consolidation_nb_agents}
                          </td>
                          <td>
                            {ligneSuiviPDP.semaine_consolidation_delai_moyen}
                          </td>
                          <td>{ligneSuiviPDP.commissionnement_nb_agents}</td>
                          <td>{ligneSuiviPDP.commissionnement_delai_moyen}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>

        <ButtonGroup isEquisized align="center" isInlineFrom="md">
          <Link
            type="button"
            className="fr-btn--md fr-btn btn-action-trigger"
            title="Export CSV"
            onClick={downloadCsv}
            to={''}
            disabled={isLoading}
            download
          >
            Export CSV
          </Link>
        </ButtonGroup>
      </section>
    </div>
  );
}

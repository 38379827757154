import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonGroup, Button } from '@dataesr/react-dsfr';

import ConsulterPropositionsCommissionnementTable from './ConsulterPropositionsCommissionnementTable';
import { enCoursDeTraitement } from '../../../configs/routes';
import { commissionnementRoutesAPI } from '../../../configs/routesAPI';
import axios from 'axios';
import TechnicalError from '../../../components/shared/technicalError/TechnicalError';

export default function PagesConsulterPropositionsCommissionnement() {
  let navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(commissionnementRoutesAPI.aTraiter.getCommissionnementsPropositions)
      .then((res) => setData(res.data.data))
      .catch((error) => {
        setApiError(error);
        setAgents([]);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const setData = (agents) => {
    setAgents(agents);
    setIsLoading(false);
  };

  return (
    <>
      {!isLoading ? <TechnicalError error={apiError} /> : <></>}
      <section className="fr-app-section">
        <ConsulterPropositionsCommissionnementTable
          agents={agents}
          isLoading={isLoading}
        ></ConsulterPropositionsCommissionnementTable>

        <div style={{ marginTop: '1.25rem' }}>
          <ButtonGroup isEquisized align="center" isInlineFrom="md">
            <Button
              onClick={() => navigate(enCoursDeTraitement.base)}
              secondary
            >
              Retour à la liste
            </Button>
          </ButtonGroup>
        </div>
      </section>
    </>
  );
}

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import LoaderCommissionnement from '../../components/shared/loaders/LoaderCommissionnement';
import TechnicalError from '../../components/shared/technicalError/TechnicalError';
import { stats } from '../../configs/routesAPI';
import { saveAs } from 'file-saver';
import { ButtonGroup, Col, Container, Row } from '@dataesr/react-dsfr';
import { Link } from 'react-router-dom';
import SelectAnnee from '../../components/shared/selects/SelectAnnee';
import { useForm } from 'react-hook-form';
import { localStorageSearchKey } from '../../configs/localStorageSearchKey';

export default function PagesStatsAgentsSuiviFormationsAnneeListe() {
  const [agentsSuiviFormationsAnnee, setAgentsSuiviFormationsAnnee] = useState(
    []
  );
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const currentYear = new Date().getFullYear();
  const [semaineMetierColspan, setsemaineMetierColspan] = useState(0);
  const [semaineTechniqueColspan, setsemaineTechniqueColspan] = useState(0);
  const [semaineConsolidationColspan, setsemaineConsolidationColspan] =
    useState(0);

  const { register, getValues, setValue } = useForm({
    defaultValues: {
      annee: currentYear - 1,
    },
  });

  const params = {
    annee: null,
  };

  useEffect(() => {
    // Initialisation des valeurs du formulaires
    let inputParam = localStorage.getItem(
      localStorageSearchKey.searchStatistiquesListe
    );
    if (inputParam !== null) {
      inputParam = JSON.parse(inputParam);
      setValue('annee', inputParam.annee);
    }
    getData();
  }, []);

  const getData = () => {
    params.annee = getValues('annee');
    localStorage.setItem(
      localStorageSearchKey.searchStatistiquesListe,
      JSON.stringify(params)
    );
    axios
      .get(stats.getAgentsSuiviFormationsAnnee, { params })
      .then((response) => {
        const data = response.data.data;
        const columns = data.colonnes;
        setColspans(columns);
        setAgentsSuiviFormationsAnnee(data.lignes);
        setColumns(columns);
        setIsLoading(false);
      })
      .catch((error) => {
        setApiError(error);
        setIsLoading(false);
      });
  };

  const setColspans = (columns) => {
    let colspanMetier = 0;
    let colspanTechnique = 0;
    let colspanConsolidation = 0;

    columns.forEach((column) => {
      if (column.name.includes('metier')) {
        colspanMetier++;
      }
      if (column.name.includes('technique')) {
        colspanTechnique++;
      }
      if (column.name.includes('consolidation')) {
        colspanConsolidation++;
      }
    });

    setsemaineMetierColspan(colspanMetier);
    setsemaineTechniqueColspan(colspanTechnique);
    setsemaineConsolidationColspan(colspanConsolidation);
  };

  const handleChange = (e) => {
    setValue(e.target.name, e.target.value);
    getData();
  };

  const downloadCsv = (event) => {
    event.preventDefault();
    params.annee = getValues('annee');

    axios({
      url: stats.exportAgentsSuiviFormationsAnnee,
      params: params,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'text/csv;',
      });
      saveAs(
        blob,
        'Synthèse_suivi_formations_agents_' + getValues('annee') + '.csv'
      );
    });
  };

  return isLoading ? (
    <LoaderCommissionnement />
  ) : (
    <>
      <div>
        {!isLoading ? <TechnicalError error={apiError} /> : <></>}

        <section className="fr-app-section">
          <form className="fr-app-form">
            <Container fluid>
              <Row className={'align-center'}>
                <Col n="3">
                  <SelectAnnee
                    register={register}
                    handleChange={handleChange}
                    anneeMin={2010}
                    anneeMax={currentYear}
                  ></SelectAnnee>
                </Col>
              </Row>
            </Container>
          </form>

          <div className="fr-table fr-app-table no-padding-top">
            <caption style={{ display: 'block' }}>
              Synthèse du suivi des formations des agents en{' '}
              {getValues('annee')}
            </caption>
            {isLoading ? (
              <LoaderCommissionnement className={'loader-center'} />
            ) : (
              <>
                <div className="tableContainer">
                  <table className="table-stats-agents-suivi-formations-annee no-caption">
                    <thead>
                      <tr className="fr-app-green-bg">
                        <th scope="col" key={'region'}></th>
                        <th
                          colSpan={semaineMetierColspan}
                          className="w-375"
                          scope="col"
                          key={'sm'}
                        >
                          Semaine métier
                        </th>
                        <th
                          colSpan={semaineTechniqueColspan}
                          className="w-375"
                          scope="col"
                          key={'st'}
                        >
                          Semaine technique
                        </th>
                        <th
                          colSpan={semaineConsolidationColspan}
                          className="w-375"
                          scope="col"
                          key={'sc'}
                        >
                          Semaine consolidation
                        </th>
                      </tr>
                      <tr>
                        {columns.map((columnObj) => {
                          return (
                            <th scope="col" key={columnObj.name}>
                              <div className="table-column-header">
                                {columnObj.label}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {agentsSuiviFormationsAnnee.map((agent, i) => {
                        return (
                          <tr
                            key={agent.id}
                            className={
                              i + 1 === agentsSuiviFormationsAnnee.length
                                ? 'fr-app-green-bg'
                                : ''
                            }
                          >
                            {columns.map((column) => {
                              return <td>{agent[column.name]}</td>;
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>

          <ButtonGroup isEquisized align="center" isInlineFrom="md">
            <Link
              type="button"
              className="fr-btn--md fr-btn btn-action-trigger"
              title="Export CSV"
              onClick={downloadCsv}
              to={''}
              disabled={isLoading}
              download
            >
              Export CSV
            </Link>
          </ButtonGroup>
        </section>
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { Title, Table, Text, Button } from '@dataesr/react-dsfr';
import SwitchProfilForm from './SwitchProfilForm';
import UserService, { EAU_ET_NATURE } from '../../services/user.service';
import ApiService from '../../services/api.service';
import axios from 'axios';
import { userRoutesAPI } from '../../configs/routesAPI';
import UserProfil from './UserProfil';
import ReactLoading from 'react-loading';
import colors from '../../styles/color.scss';
import DernieresFichesConsulteesService from '../../services/dernieresfichesconsultees.service';
import LocalStorageService from '../../services/localstorage.service';

export default function SwitchProfil() {
  const loaderColor =
    EAU_ET_NATURE === UserService.applicationContext()
      ? colors.apporange
      : colors.appgreen;
  const [isLoading, setIsLoading] = useState(true);
  const user = UserService.getCurrentUser();
  const effectiveProfil = user.effective_profil;
  console.log({ user });

  const [originalProfil, setOriginalProfil] = useState({
    application_context: null,
    role_label: null,
    types_commissionnement: [],
    organisme_gestionnaire_id: null,
    organisme_gestionnaire: null,
    sous_organisme_id: null,
  });

  useEffect(() => {
    ApiService.fetchData(
      userRoutesAPI.getOriginalProfil,
      originalProfilReceived
    );
  }, []);

  const originalProfilReceived = (originalProfil) => {
    setOriginalProfil(originalProfil);
    setIsLoading(false);
  };

  console.log({ originalProfil });

  const switchBackProfil = () => {
    axios
      .post(userRoutesAPI.switchBackProfil)
      .then(function (response) {
        if (200 === response.status) {
          console.log({ response });
          UserService.setUser(response.data.data);
          DernieresFichesConsulteesService.reset();
          LocalStorageService.cleanSearch();
          // we need to update the colors ; navigate() will not refresh the parent headers
          window.location.href = UserService.getDefaultRoute();
          return;
        }

        console.log('error', { response });
      })
      .catch(function (error) {
        console.log({ error });
      });
  };

  return (
    <section className="fr-app-section">
      <Title as="h3" look="h5" className="fr-app-bloc-title">
        Gestion des profils
      </Title>

      <div className="tableContainerWithLoader">
        <Table
          className="fr-app-table no-padding-top no-caption two-cols-list-tab fr-app-switch-profil"
          rowKey={() => 0}
          data={[{}]} // display only one line
          columns={[
            {
              name: 'profilactuel',
              label: 'Profil actuel',
              render: () => {
                return <UserProfil profil={effectiveProfil} />;
              },
            },
            {
              name: 'profilprecedent',
              label: 'Profil originel',
              render: () => {
                return UserService.hasSwitchedProfil() ? (
                  isLoading ? (
                    <div className="loader-center">
                      <ReactLoading
                        type="spin"
                        color={loaderColor}
                        height={100}
                        width={100}
                      />
                    </div>
                  ) : (
                    <>
                      <UserProfil profil={originalProfil} />
                      <Button
                        title="Revenir au profil originel"
                        size="sm"
                        onClick={switchBackProfil}
                      >
                        Revenir au profil originel
                      </Button>
                    </>
                  )
                ) : (
                  <span className="btn-float-right">Profil inchangé</span>
                );
              },
            },
          ]}
        />
      </div>

      <Text className="marianne-bold fr-app-text-align-center" size="md">
        Changer de profil
      </Text>
      {isLoading ? (
        <div className="loader-center">
          <ReactLoading
            type="spin"
            color={loaderColor}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <SwitchProfilForm originalProfil={originalProfil} />
      )}
    </section>
  );
}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { enCoursDeTraitement as routesEnCoursDeTraitement } from '../../configs/routes';
import UserService, { EAU_ET_NATURE, ICPE } from '../../services/user.service';
import { enCoursDeTraitementRouteAPI } from '../../configs/routesAPI';
import ReactLoading from 'react-loading';
import colors from '../../styles/color.scss';
import axios from 'axios';

export default function PagesEnCoursDeTraitement() {
  const [enCoursDeTraitement, setEnCoursDeTraitement] = useState([]);

  const isIcpe = ICPE === UserService.applicationContext();
  const isEauEtNature = EAU_ET_NATURE === UserService.applicationContext();
  const loaderColor = isEauEtNature ? colors.apporange : colors.appgreen;

  useEffect(() => {
    const urls = [
      axios.get(enCoursDeTraitementRouteAPI + '?part=1'),
      axios.get(enCoursDeTraitementRouteAPI + '?part=2'),
      axios.get(enCoursDeTraitementRouteAPI + '?part=3'),
    ];
    let allResults = [];
    axios.all(urls).then(([part1, part2, part3]) => {
      allResults = Object.assign(part1.data.data, allResults);
      allResults = Object.assign(part2.data.data, allResults);
      allResults = Object.assign(part3.data.data, allResults);
      setEnCoursDeTraitement(JSON.parse(JSON.stringify(allResults)));
    });
  }, []);

  const getRow = (label, path, value) => {
    return (
      <tr>
        <td>
          <Link to={path}>{label}</Link>
        </td>
        <td style={{ paddingTop: '5px', paddingBottom: '0px' }}>
          <div style={{ display: 'inline-flex' }}>
            {value === undefined ? (
              <ReactLoading
                type="spin"
                color={loaderColor}
                width={25}
                height={25}
              />
            ) : (
              <>{value}</>
            )}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <section className="fr-app-section">
        <div className="fr-table fr-app-table no-padding-top no-margin-bottom no-caption fr-app-dashboard">
          <table>
            <caption>En cours de traitement</caption>
            <thead>
              <tr>
                <th scope="col" key="0">
                  En cours de traitement
                </th>
                <th scope="col" key="1">
                  Nombre de cas
                </th>
              </tr>
            </thead>
            <tbody>
              <>
                {isIcpe ? (
                  <>
                    {getRow(
                      'Agents - Créations en attente',
                      routesEnCoursDeTraitement.agentsCreationsEnAttente,
                      enCoursDeTraitement.demandes_creation_agent
                    )}
                    {getRow(
                      'Formations - Inscriptions en attente',
                      routesEnCoursDeTraitement.inscriptionsEnAttente,
                      enCoursDeTraitement.demandes_inscription_en_attente
                    )}
                    {getRow(
                      'Formations - Demandes de transfert',
                      routesEnCoursDeTraitement.demandesDeTransfert,
                      enCoursDeTraitement.demandes_transfert_en_attente
                    )}
                  </>
                ) : (
                  ''
                )}

                {getRow(
                  'Commissionnements - Propositions en attente',
                  routesEnCoursDeTraitement.commissionnementsPropositions,
                  enCoursDeTraitement.commissionnement_propositions
                )}
                {getRow(
                  "Commissionnements - En attente de l'arrêté ministériel",
                  routesEnCoursDeTraitement.commissionnementsEnAttenteArrete,
                  enCoursDeTraitement.commissionnements_en_attente_arrete
                )}
                {getRow(
                  "Commissionnements - En attente de l'édition de la carte",
                  routesEnCoursDeTraitement.commissionnementsCartesAEditer,
                  enCoursDeTraitement.commissionnement_cartes_a_editer
                )}
                {getRow(
                  'Commissionnements - Changements de zone en attente',
                  routesEnCoursDeTraitement.changementsZoneEnAttente,
                  enCoursDeTraitement.propositions_changement_zone
                )}
                {isEauEtNature
                  ? getRow(
                      'Commissionnements - Changements de commissionnement en attente',
                      routesEnCoursDeTraitement.changementsCommissionnementEnAttente,
                      enCoursDeTraitement.changements_commissionnement_en_attente
                    )
                  : ''}
                {getRow(
                  'Prestations de serment - En attente',
                  routesEnCoursDeTraitement.prestationsEnAttente,
                  enCoursDeTraitement.prestations_en_attente
                )}
                {getRow(
                  'Décommissionnements - En attente de l’arrêté ministériel',
                  routesEnCoursDeTraitement.decommissionnementsEnAttenteArrete,
                  enCoursDeTraitement.decommissionnements_en_attente_arrete
                )}
              </>
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
